import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { Button } from "@fluentui/react-components";
import Logo from "../assets/logo_full_name.svg";

const Login = () => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/landing");
    }
  }, [isAuthenticated, navigate]);

  const handleLogin = () =>
    void (async () => {
      await loginWithRedirect({
        appState: {
          returnTo: "/landing",
        },
      });
    })();

  const handleSignUp = () =>
    void (async () => {
      await loginWithRedirect({
        appState: {
          returnTo: "/landing",
        },
        authorizationParams: {
          screen_hint: "signup",
        },
      });
    })();

  if (isLoading || isAuthenticated) {
    return;
  }

  return (
    <div
      className="h-screen w-screen flex items-center justify-center"
      style={{
        background: "linear-gradient(117deg, #1D5249 0%, #002922 92.33%)",
      }}
    >
      <div className="flex flex-col gap-2 max-w-[400px] rounded-xl bg-white shadow-[0_20px_24px_-4px_rgba(16,24,40,0.08),0_8px_8px_-4px_rgba(16,24,40,0.03)]">
        <div className="p-6 pb-0 flex flex-col gap-4 items-center self-stretch">
          <img
            className="w-[200px] h-[36.473px] object-contain"
            src={Logo}
            alt="Venturous logo"
          />
          <div className="flex flex-col gap-1 items-start self-stretch">
            <p className="self-stretch text-center text-base font-semibold text-[#0C0D0D] tracking-[-0.16px]">
              Welcome to Venturous
            </p>
            <p className="self-stretch text-center text-sm text-[#787D7C]">
              We connect healthcare companies with fractional executive talent.
            </p>
          </div>
        </div>
        <div className="p-6 flex gap-4 items-center">
          <Button
            style={{
              flex: "1",
              padding: "12px 16px",
              fontSize: "14px",
              borderRadius: "8px",
            }}
            appearance="secondary"
            size="medium"
            onClick={handleSignUp}
          >
            Sign Up
          </Button>
          <Button
            style={{
              flex: "1",
              padding: "12px 16px",
              fontSize: "14px",
              borderRadius: "8px",
              background: "linear-gradient(180deg, #003C32 0%, #00332A 100%)",
            }}
            appearance="primary"
            size="medium"
            onClick={handleLogin}
          >
            Log In
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Login;
