import {
  ArrayInput,
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  List,
  ReferenceField,
  ReferenceInput,
  SelectField,
  SelectInput,
  Show,
  SimpleForm,
  SimpleFormIterator,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin";
import {
  CandidateOpportunityProfileField,
  StringArrayField,
} from "./CustomFields";
import { candidateApplicationStatusList } from "../../types";

const statusChoices = candidateApplicationStatusList.map((type) => ({
  id: type,
  name: type,
}));

const filters = [
  <ReferenceInput key="candidate" source="candidate" reference="candidates" />,
  <ReferenceInput
    key="job_opening"
    source="job_opening"
    reference="job_openings"
  />,
  <SelectInput key="status" source="status" choices={statusChoices} />,
  <BooleanInput key="agrees_with_scope" source="agrees_with_scope" />,
  <TextInput key="scope" source="scope" />,
  <BooleanInput key="agrees_with_budget" source="agrees_with_budget" />,
  <TextInput key="budget" source="budget" />,
  <BooleanInput key="open_to_full_time" source="open_to_full_time" />,
  <BooleanInput key="agrees_with_start_date" source="agrees_with_start_date" />,
  <DateInput key="start_date" source="start_date" />,
  <TextInput key="opt_out_reasons" source="opt_out_reasons" />,
  <DateInput key="opt_in_date" source="opt_in_date" />,
  <DateInput key="opt_out_date" source="opt_out_date" />,
  <DateInput key="created_at" source="created_at" />,
];

export const CandidateStatusList = () => (
  <List filters={filters}>
    <Datagrid>
      <ReferenceField source="candidate" reference="candidates" />
      <ReferenceField source="job_opening" reference="job_openings" />
      <CandidateOpportunityProfileField />
      <SelectField source="status" choices={statusChoices} />
      <BooleanField source="agrees_with_scope" />
      <TextField source="scope" />
      <BooleanField source="agrees_with_budget" />
      <TextField source="budget" />
      <BooleanField source="open_to_full_time" />
      <BooleanField source="agrees_with_start_date" />
      <DateField source="start_date" />
      <BooleanField source="opt_in_requested" />
      <TextField source="opt_out_reasons" />
      <DateField source="opt_in_date" />
      <DateField source="opt_out_date" />
      <DateField source="created_at" />
    </Datagrid>
  </List>
);

export const CandidateStatusShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField source="candidate" reference="candidates" />
      <ReferenceField source="job_opening" reference="job_openings" />
      <CandidateOpportunityProfileField />
      <SelectField source="status" choices={statusChoices} />
      <BooleanField source="agrees_with_scope" />
      <TextField source="scope" />
      <BooleanField source="agrees_with_budget" />
      <TextField source="budget" />
      <BooleanField source="open_to_full_time" />
      <BooleanField source="agrees_with_start_date" />
      <DateField source="start_date" />
      <StringArrayField source="match_criteria" />
      <StringArrayField source="match_criteria_suggestions" />
      <StringArrayField source="match_criteria_quotes" />
      <TextField source="personality_role_strength" />
      <TextField source="personality_role_strength_detail" />
      <TextField source="behavior_role_strength" />
      <TextField source="behavior_role_strength_detail" />
      <TextField source="motivator_role_strength" />
      <TextField source="motivator_role_strength_detail" />
      <BooleanField source="opt_in_requested" />
      <TextField source="opt_out_reasons" />
      <DateField source="opt_in_date" />
      <DateField source="opt_out_date" />
      <DateField source="created_at" />
    </SimpleShowLayout>
  </Show>
);

export const CandidateStatusCreate = () => (
  <Create>
    <SimpleForm>
      <ReferenceInput source="candidate" reference="candidates" />
      <ReferenceInput source="job_opening" reference="job_openings" />
      <SelectInput source="status" choices={statusChoices} />
      <BooleanInput source="agrees_with_scope" />
      <TextInput source="scope" />
      <BooleanInput source="agrees_with_budget" />
      <TextInput source="budget" />
      <BooleanInput source="open_to_full_time" />
      <BooleanInput source="agrees_with_start_date" />
      <DateInput source="start_date" />
      <ArrayInput source="match_criteria">
        <SimpleFormIterator>
          <TextInput source="" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="match_criteria_suggestions">
        <SimpleFormIterator>
          <TextInput source="" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="match_criteria_quotes">
        <SimpleFormIterator>
          <TextInput source="" />
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput source="personality_role_strength" />
      <TextInput source="personality_role_strength_detail" />
      <TextInput source="behavior_role_strength" />
      <TextInput source="behavior_role_strength_detail" />
      <TextInput source="motivator_role_strength" />
      <TextInput source="motivator_role_strength_detail" />
      <BooleanInput source="opt_in_requested" />
      <TextInput source="opt_out_reasons" />
      <DateInput source="opt_in_date" />
      <DateInput source="opt_out_date" />
      <DateInput source="created_at" />
    </SimpleForm>
  </Create>
);

export const CandidateStatusEdit = () => (
  <Edit>
    <SimpleForm>
      <ReferenceInput source="candidate" reference="candidates" />
      <ReferenceInput source="job_opening" reference="job_openings" />
      <SelectInput source="status" choices={statusChoices} />
      <BooleanInput source="agrees_with_scope" />
      <TextInput source="scope" />
      <BooleanInput source="agrees_with_budget" />
      <TextInput source="budget" />
      <BooleanInput source="open_to_full_time" />
      <BooleanInput source="agrees_with_start_date" />
      <DateInput source="start_date" />
      <ArrayInput source="match_criteria">
        <SimpleFormIterator>
          <TextInput source="" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="match_criteria_suggestions">
        <SimpleFormIterator>
          <TextInput source="" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="match_criteria_quotes">
        <SimpleFormIterator>
          <TextInput source="" />
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput source="personality_role_strength" />
      <TextInput source="personality_role_strength_detail" />
      <TextInput source="behavior_role_strength" />
      <TextInput source="behavior_role_strength_detail" />
      <TextInput source="motivator_role_strength" />
      <TextInput source="motivator_role_strength_detail" />
      <BooleanInput source="opt_in_requested" />
      <TextInput source="opt_out_reasons" />
      <DateInput source="opt_in_date" />
      <DateInput source="opt_out_date" />
      <DateInput source="created_at" />
    </SimpleForm>
  </Edit>
);
